/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%;
}

.app-wrapper {
  padding: 30px;
  width: 100%;

  @media screen and (max-width: 575px) {
    padding: 20px;
  }
  .add-item {
    min-height: 50px;
  }
  .time-settings {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .MuiSelect-selectMenu {
    height: auto;
    overflow: auto;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .reports-table td.MuiTableCell-root.MuiTableCell-body {
    max-width: 200px;
  }
}

#menu- {
  .MuiMenu-paper.MuiPopover-paper {
    width: auto !important;
  }
}
